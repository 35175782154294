import React, { useState } from 'react'
import '../styles/header.scss'
import { Link } from 'gatsby'
import logo from '../images/logo.png'
import HamburgerMenu from 'react-hamburger-menu'
import { useStaticQuery, graphql } from 'gatsby'

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { frontmatter: { key: { eq: "header" } } }) {
        edges {
          node {
            frontmatter {
              menu {
                name
                path
              }
            }
          }
        }
      }
    }
  `)

  const menu = data.allMarkdownRemark.edges[0].node.frontmatter.menu

  const [isOpen, setIsOpen] = useState(false)
  const [changeColor, setChangeColor] = useState(false)

  const handleColorChange = () => {
    if (changeColor == false) {
      setChangeColor(true)
    } else if (changeColor == true) {
      setChangeColor(false)
    }
  }

  let color = changeColor
    ? 'header-fullscreen-colorchange'
    : 'header-fullscreen'

  return (
    <div>
      <div className={color}>
        <div>
          <Link to="/">
            <img className="logo" src={logo} alt="iHug logo"></img>
          </Link>
        </div>

        <ul className="navbar-ul">
          {menu.map((item, i) => (
            <li key={i} className="navbar-li">
              <Link className="link" to={item.path}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <HamburgerMenu
        className="hamburger-menu"
        isOpen={isOpen}
        menuClicked={() => setIsOpen(!isOpen) + handleColorChange()}
        width={30}
        height={30}
        strokeWidth={2}
        rotate={0}
        color="black"
        borderRadius={0}
        animationDuration={0.5}
      />

      {isOpen ? (
        <div className="hamburger-overlay">
          <ul className="hamburger-ul">
            <li className="hamburger-li">
              <Link className="link" to="/">
                Hem
              </Link>
            </li>
            <li className="hamburger-li">
              <Link className="link" to="/support">
                Support
              </Link>
            </li>
            <li className="hamburger-li">
              <Link className="link" to="/contact">
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  )
}
export default Header
