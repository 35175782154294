import * as React from 'react'
import '../styles/footer.scss'
import { Link } from 'gatsby'
import logoWithoutText from '../images/logo-without-text.png'
import { useStaticQuery, graphql } from 'gatsby'

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { frontmatter: { key: { eq: "header" } } }) {
        edges {
          node {
            frontmatter {
              menu {
                name
                path
              }
              quote
              quote2
            }
          }
        }
      }
    }
  `)

  const menu = data.allMarkdownRemark.edges[0].node.frontmatter.menu
  const md = data.allMarkdownRemark.edges[0].node.frontmatter

  return (
    <div id="footer">
      <img className="footer-logo" src={logoWithoutText} alt="logo"></img>
      <p className="footer-text">
        {md.quote}
        <br></br>
        {md.quote2}
      </p>
      <ul className="footer-ul">
        {menu.map((item, i) => (
          <li key={i} className="footer-li">
            <Link className="footer-link" to={item.path}>
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
      <p className="footer-text">support@ihug.se</p>
    </div>
  )
}
export default Footer
